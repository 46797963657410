<template>
  <b-container
    class="bv-example-row mb-3"
    fluid
  >
    <hr v-if="!spinner">
    <b-spinner
      v-if="spinner"
      variant="primary"
      class="spinners"
    />
    <div v-if="!spinner">
      <b-card id="printMe">
        <b-row>
          <b-col cols="8">
            <h4>
              {{ tarih }} Tarihli Konaklama Listesi (
              {{ tarifeGet($route.query.tarife) }} Konaklama Tarifesi)
            </h4>
          </b-col>
          <b-col
            cols="4"
            class="text-right unPrint"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="bgInfo"
              to="/aylikRapor"
              class="myButton mr-50"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                class="mr-50"
              />
              Geri Dön
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-print="'#printMe'"
              variant="bgInfo"
            >
              <feather-icon
                icon="PrinterIcon"
                class="mr-50"
              />
              Listeyi Yazdır
            </b-button>
          </b-col>
        </b-row>
        <hr>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">
                ODA
              </th>
              <th scope="col">
                REZERVASYON NO
              </th>
              <th scope="col">
                KONUK İSMİ
              </th>
              <th scope="col">
                GİRİŞ TARİHİ
              </th>
              <th scope="col">
                ÇIKIŞ TARİHİ
              </th>
              <th scope="col">
                DURUM
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in this.$store.getters.raporGunDetayReturn"
              :key="index"
            >
              <td class="bold">
                <a @click="rezGo(item.rezervasyonNo)">
                  {{ item.odaKodu }}
                </a>
              </td>
              <td class="bold">
                <a @click="rezGo(item.rezervasyonNo)">{{
                  item.rezervasyonNo
                }}</a>
              </td>
              <td class="bold">
                <a
                  @click="rezGo(item.rezervasyonNo)"
                >{{ item.adi }} {{ item.soyAdi }}</a>
              </td>
              <td>{{ item.girisTarih }}</td>
              <td>{{ item.cikisTarih }}</td>
              <td class="bold">
                {{
                  item.checkOut == 0
                    ? "Tahsilat Bek. / Aktif Kon."
                    : "Tahsilat Tam. / Çıkış Yapıldı."
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return { spinner: true, tarih: this.$route.query.tarih || '' }
  },
  computed: {},
  mounted() {},
  beforeDestroy() {
    this.$store.commit('clearBekleyenTah')
  },
  created() {
    const { tarife } = this.$route.query
    const { tarih } = this.$route.query
    this.$store
      .dispatch('raporGun', {
        tarife,
        tarih,
      })
      .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
        const mesaj = this.$store.getters.notificationSettings(res)
        if (mesaj) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            },
            { position },
          )
        }
      })
      .catch(err => {
          this.spinner = false
        const mesaj = this.$store.getters.notificationSettings(err)
        if (mesaj) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: mesaj,
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        }
      })
  },
  methods: {
    tarifeGet(tarifeID) {
      const veri = Object.values(this.$store.getters.tarife).find(obj => obj.tarifeID == tarifeID)
      if (veri) {
        return veri.tarifeAdi
      }
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
}
</script>

<style scoped>
.bold {
  font-weight: 600;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 10px !important;
  background-color: #31526f;
  color: white;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #93bee1 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #009879 !important;
  color: white !important;
}

tr,
tbody {
  text-align: center !important;
}
</style>
<style>
.content-wrapper {

}
</style>
